<template>
  <div class="p-5">
    <form
      class="flex items-center border-b-2 py-2 w-full mx-auto bg-white border-primary"
      @submit.prevent="find"
    >
      <input
        class="appearance-none bg-transparent border-none w-full text-gray-700 focus:text-black px-3 leading-tight focus:outline-none"
        type="text"
        placeholder="Fragment tytułu lub treści"
        v-model="search"
      />
      <button
        class="flex-shrink-0 bg-secondary hover:bg-primary border-secondary hover:border-primary text-sm border-4 text-white py-1 px-2 mx-3 rounded"
        type="submit"
      >Wyszukaj</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "Search",
  data() {
    return {
      search: ""
    };
  },
  methods: {
    find() {
      this.$router.push("/blogsearch/" + this.search.trim());
    }
  }
};
</script>