<template>
  <div v-if="error" class="p-10 text-center">
    <h2 class="py-2 text-xl leading-tight lg:text-4xl">
      Przykro mi nie ma tutaj nic :(
    </h2>
    <p>
      <router-link to="/blog" class="underline hover:text-primary"
        >Wróć do bloga</router-link
      >
    </p>
  </div>
  <div class="text-gray-200" v-else>
    <div class="flex flex-wrap">
      <div v-for="item in blog.data" :key="item.id" class="w-full p-5 lg:w-1/2">
        <router-link
          :to="`/blog/${item.name}`"
          class="block p-3 text-black bg-gray-200 rounded  hover:text-gray-200 hover:bg-primary"
        >
          <img
            :src="`/storage/blogs/${item.file}`"
            :alt="item.title"
            v-if="item.file"
          />
          <h4
            v-html="item.title"
            class="px-10 py-4 text-2xl font-extrabold text-center lg:text-4xl"
          ></h4>
          <p v-html="item.description"></p>
        </router-link>
      </div>
    </div>
    <ul class="flex px-5" v-if="blog.last_page > 1">
      <li class="p-0">
        <router-link
          tag="button"
          class="flex-shrink-0 px-2 py-1 text-sm text-white border-4  bg-secondary hover:bg-primary border-secondary hover:border-primary"
          :to="`/blog-${prev}`"
          v-if="page != 1"
          >Poprzedni</router-link
        >
      </li>
      <li class="p-0" v-for="pageNumber in blog.last_page" :key="pageNumber">
        <router-link
          class="flex-shrink-0 px-2 py-1 text-sm text-white border-4  bg-secondary hover:bg-primary border-secondary hover:border-primary"
          tag="button"
          :to="`/blog-${pageNumber}`"
          v-if="!$route.params.page && pageNumber != 1"
          >{{ pageNumber }}</router-link
        >
        <button
          class="flex-shrink-0 px-2 py-1 text-sm font-bold text-white border-4 opacity-75 cursor-not-allowed  bg-primary border-primary"
          :to="`/blog-${pageNumber}`"
          v-if="!$route.params.page && pageNumber == 1"
        >
          {{ pageNumber }}
        </button>
        <router-link
          class="flex-shrink-0 px-2 py-1 text-sm text-white border-4  bg-secondary hover:bg-primary border-secondary hover:border-primary"
          tag="button"
          :to="`/blog-${pageNumber}`"
          v-if="$route.params.page && $route.params.page != pageNumber"
          >{{ pageNumber }}</router-link
        >
        <button
          class="flex-shrink-0 px-2 py-1 text-sm font-bold text-white border-4 opacity-75 cursor-not-allowed  bg-primary border-primary"
          v-if="$route.params.page && $route.params.page == pageNumber"
        >
          {{ pageNumber }}
        </button>
      </li>
      <li class="p-0">
        <router-link
          tag="button"
          class="flex-shrink-0 px-2 py-1 text-sm text-white border-4  bg-secondary hover:bg-primary border-secondary hover:border-primary"
          :to="`/blog-${next}`"
          v-if="page < blog.last_page"
          >Następny</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Table",
  data() {
    return {
      blog: [],
      page: 1,
      prev: 0,
      next: 2,
      error: false,
    };
  },
  watch: {
    $route: "fetchData",
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      var number = 1;
      if (this.$route.params.page) {
        number = this.$route.params.page;
      }
      this.page = number;
      this.prev = number - 1;
      this.next = number * 1 + 1;
      await axios
        .get("/api/app/blog?page=" + this.page)
        .then((response) => {
          this.blog = response.data;
          if (this.page != response.data.current_page) {
            this.error = true;
          }
        })
        .catch(() => {
          this.error = true;
        });
    },
  },
};
</script>